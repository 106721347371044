@charset "utf-8";

/***********
	Fonts
***********/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/***********
	General
***********/
body {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-weight: 400;
	background: #f4f4f4;
	color: #454139;
	overflow-x: hidden;
}

a, a:hover, a:visited, a:active, a:link{
	color: inherit;
	text-decoration: none;
}

table, th, td {
    border: 1px solid black;
}

.load_screen {
    position: absolute;
    z-index: 110;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .2);
    width: 100%;
    height: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #FFFFFF
}
/***********
	Header
***********/
.header {
	position: fixed;
	top: 0;
	left: 0;
    width: 100%;
    z-index: 100;
}

.header_container {
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(69, 65, 57, 0.2);
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.menu > div {
    margin-left: 15px;
}

/***********
	container
***********/
.container {
    position: relative;
    top: 50px;
    z-index: 99;
}

/***********
	account
***********/
.account_container {
    padding: 50px 50px;

}

.user_profile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    font-size: 26px;
}

.user_profile > div {
    margin: 10px;;
}

/***********
	Home
***********/
.dashboard {
    width: 100%;
    height: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    align-content: center;
}

.portfolio_dashboard {
    width: 61.8%;
}

.portfolio_dashboard_header {
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    align-content: center;
}

.portfolio_dashboard_header_asset {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}

.portfolio_dashboard_header_search_result {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}

.portfolio_dashboard_header_search_result_item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.portfolio_dashboard_header_filter {
    height: 50%;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
}

.portfolio_dashboard_header_search {
    height: 50%;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
}

.portfolio_dashboard_portfolio_summary {
    width: 50%;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
}

.portfolio_dashboard_list {
    height: 700px;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
    overflow: scroll;
    padding: 10px;
}

.portfolio_asset_category_group {
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .1);
    border-radius: 1em;
    border: .1em solid rgba(0, 0, 0, 0.18);
    padding: 10px;
}

.edit_asset_category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: center;
}

.edit_asset_category_button {
    line-height: 24px; 
	box-sizing: border-box;
	border: none;
	outline: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	top: 0;
	right: 0;
	background: transparent; 
    color: #454139;
	padding: 0;
}

.edit_asset_category_button:before {
    content: "edit";
    font-family: "Material Icons";
    font-size: 24px;
}

.edit_asset_category_area {
    width: 100%;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .1);
    border-radius: 1em;
    border: .1em solid rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.edit_asset_category_current_category {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.asset_category_tag {
    background-color: #dad5d5;
    border: 1px solid #000000;
    margin: 20px;
    padding: 5px;
}

.add_button {
    line-height: 24px; 
	box-sizing: border-box;
	border: none;
	outline: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	top: 0;
	right: 0;
	background: transparent; 
    color: #454139;
	padding: 0;
}

.add_button:before {
    content: "add_box";
    font-family: "Material Icons";
    font-size: 24px;
}

.readmore_button {
    line-height: 24px; 
	box-sizing: border-box;
	border: none;
	outline: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	top: 0;
	right: 0;
	background: transparent; 
    color: #454139;
	padding: 0;
}

.readmore_button:before {
    content: "read_more";
    font-family: "Material Icons";
    font-size: 24px;
}

.edit_button {
    line-height: 24px; 
	box-sizing: border-box;
	border: none;
	outline: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	top: 0;
	right: 0;
	background: transparent; 
    color: #454139;
	padding: 0;
}

.edit_button:before {
    content: "edit";
    font-family: "Material Icons";
    font-size: 24px;
}

.portfolio_asset_category_group_name {
    font-size: 24px;
    font-style: italic;
}


.dashboard_tags_filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.dashboard_tags_filter_tag {
    background-color: #dad5d5;
    border: 1px solid #000000;
    padding: 5px;
    margin: 2px;
    cursor: pointer;
}


.dashboard_tags_filter_tag_active {
    background-color: green;
    border: 1px solid #000000;
    padding: 5px;
    margin: 2px;
    cursor: pointer;
}

.journal_dashboard {
    width: 38.2%;
}

.journal_dashboard_header {
    height: 100px;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}

.journal_dashboard_list {
    height: 900px;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
}

.search_bar {
    position: relative;
	width: 180px;
	margin: 0 auto;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.search_button {
    line-height: 24px; 
	box-sizing: border-box;
	border: none;
	outline: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	top: 0;
	right: 0;
	background: transparent; 
    color: #454139;
	padding: 0;
}

.search_button:before {
    content: "search";
    font-family: "Material Icons";
    font-size: 24px;
}

.confirm_button {
    line-height: 24px; 
	box-sizing: border-box;
	border: none;
	outline: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	top: 0;
	right: 0;
	background: transparent; 
    color: #454139;
	padding: 0;
}

.confirm_button:before {
    content: "check_box";
    font-family: "Material Icons";
    font-size: 24px;
}

.portfolio_asset_category_group_list_item:hover {
    color: forestgreen;
}

/***********
	Asset
***********/
.asset_dashboard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}

.asset_dashboard_detail {
    height: 300px;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
}

.asset_dashboard_detail_content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
    height: 100%;
}

.asset_dashboard_detail_content > div {
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .1);
    border-radius: 1em;
    border: .1em solid rgba(0, 0, 0, 0.18);
    padding: 10px;
    margin: 5px;
}

.asset_dashboard_action_and_journal {
    height: 700px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: center;
}

.asset_dashboard_action {
    width: 50%;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
}

.asset_dashboard_journal {
    width: 50%;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .2);
    border-radius: 1em;
    border: .4em solid rgba(0, 0, 0, 0.18);
}

.asset_customer_field {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}

.asset_customer_field > div {
    margin-right: 10px;
}

.asset_add_customer_field {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}

.asset_dashboard_detail_content_info {
    width: 30%;
}

.asset_dashboard_detail_tags {
    width: 20%;
}

.asset_dashboard_detail_cats {
    width: 20%;
}

.asset_dashboard_detail_fileds {
    width: 20%;
}

.asset_action_board {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: center;
}

.asset_add_action_button {
    line-height: 24px; 
	box-sizing: border-box;
	border: none;
	outline: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	top: 0;
	right: 0;
	background: transparent; 
    color: #454139;
	padding: 0;
}

.asset_add_action_button:before {
    content: "add_box";
    font-family: "Material Icons";
    font-size: 24px;
}

.asset_add_action_area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 100%;
}

.asset_add_action_area div {
    padding: 5px;
}

.asset_add_action_area input {
    width: 50px;
}

.asset_action_board_List_item {
    width: 100%;
    box-shadow: .1em .1em .2em rgba(0, 0, 0, .1);
    border-radius: 1em;
    border: .1em solid rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.asset_journal_board_header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: center;
}

/***********
	Journal
***********/
.add_journal_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.jounal_title_input {
    width: 800px;
    margin-top: 20px;;
}

.jounal_content_input {
    width: 100%;
    margin: 20px auto;
}

.journals_page_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.journals_page_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.journals_page_list_item {
    font-size: 28px;
    padding-top: 10px;
}

.journals_page_list_item:hover {
    color: forestgreen;
}

.journal_title {
    font-size: 28px;
    padding: 20px;
}

.journal_content {
    font-size: 20px;
    padding: 10px;
}

.journal_dashboard_list_item:hover {
    color: forestgreen;
}